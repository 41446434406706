<template>
<div>
  <vs-row vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
      <vs-card>
        <h2 class="card-title d-flex">{{$t('MAINSETTINGS.HEADER.TITEL')}}
          <div class="ml-auto text-right">
            <vs-button  @click="LoadData()" size="small" color="primary" type="filled" icon="refresh"></vs-button>
          </div>
        </h2>
        <p class="card-subtitle">
          <span>{{$t('MAINSETTINGS.HEADER.TEXT')}}</span>
        </p>
      </vs-card>
    </vs-col>
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
      <vs-card v-if="editMode" >
        <div id="editBannerCard" class="vs-con-loading__container">
          <h3>{{$t('MAINSETTINGS.BANNERS.EDIT.HEADER')}}</h3>
          <div class="default-input d-flex align-items-center">
            <vs-input :label="$t('MAINSETTINGS.BANNERS.EDIT.LABEL.NAME')"  class="inputx" :placeholder="$t('MAINSETTINGS.BANNERS.EDIT.LABEL.NAME')"  v-model="selected.name" />
            <vs-input :label="$t('MAINSETTINGS.BANNERS.EDIT.LABEL.DESCR')"  class="inputx ml-3" :placeholder="$t('MAINSETTINGS.BANNERS.EDIT.LABEL.DESCR')"  v-model="selected.descripton" />
            <vs-input :label="$t('MAINSETTINGS.BANNERS.EDIT.LABEL.TITLE')"  class="inputx ml-3" :placeholder="$t('MAINSETTINGS.BANNERS.EDIT.LABEL.TITLE')"  v-model="selected.title" />
            <vs-input :label="$t('MAINSETTINGS.BANNERS.EDIT.LABEL.TEXT')"  class="inputx ml-3" :placeholder="$t('MAINSETTINGS.BANNERS.EDIT.LABEL.TEXT')"  v-model="selected.text" />
            <vs-select 
              :label="$t('MAINSETTINGS.BANNERS.EDIT.LABEL.COLOR')"
              class="ml-3 "
              v-model="selected.color">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in colors" />
            </vs-select>
          </div>
          <div class="default-input d-flex align-items-center mt-3">
            <DatePicker class="" :Label="$t('MAINSETTINGS.BANNERS.EDIT.LABEL.DATE_FROM')" :OkCancelLabel="{ok:$t('COM.OK'),cancel:$t('COM.CANCEL')}" :PopUpTitel="$t('MAINSETTINGS.BANNERS.EDIT.POPUP.TITLE.DATE_FROM')" v-model="selected.start"></DatePicker>
            <DatePicker class="" :Label="$t('MAINSETTINGS.BANNERS.EDIT.LABEL.DATE_TO')" :OkCancelLabel="{ok:$t('COM.OK'),cancel:$t('COM.CANCEL')}" :PopUpTitel="$t('MAINSETTINGS.BANNERS.EDIT.POPUP.TITLE.DATE_TO')" v-model="selected.end"></DatePicker>
            <vs-select 
              :label="$t('MAINSETTINGS.BANNERS.EDIT.GROUPS.LABEL')"
              class=""
              v-model="selected.group_fk">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in allGroups" />
            </vs-select>
            <vs-select 
            :label="$t('MAINSETTINGS.BANNERS.EDIT.USER.LABEL')"
            class="ml-3"
            v-model="selected.userguid" >
                <vs-select-item :key="index+'_user'" :value="item.value" :text="item.text" v-for="(item,index) in user" />
            </vs-select>
          </div>    
          <div class="default-input d-flex align-items-center mt-3">
              <vs-checkbox v-model="selected.active">{{$t('MAINSETTINGS.BANNERS.EDIT.LABEL.ACTIVE')}}</vs-checkbox>
              <vs-checkbox v-model="selected.public">{{$t('MAINSETTINGS.BANNERS.EDIT.LABEL.PUBLIC')}}</vs-checkbox>
              <vs-input-number min=0 class="ml-3" v-model="selected.sortorder" label="Reihenfolge:"/>
          </div> 
          <SaveCancel class="mt-3" :SaveAllowed = !bannerDataChanged v-on:SaveClicked="Save" v-on:CancelClicked="Cancel"></SaveCancel>   
        </div>
      </vs-card>
      <vs-card >
        <vs-tabs>
          <vs-tab :label="$t('MAINSETTINGS.TAB1.HEADER')">
            <div class="">
              <div class="d-flex align-items-center">
                <div class="mt-3">
                  <h6>{{pollingIntervallLabelText}}</h6>
                  <vs-slider color="primary" :text-fixed="$t('MAINSETTINGS.SETTINGS.POLLING_INTERVALL.POINT')" ticks max=15 v-model="mainsettings.polling_intervall_min"/>
                  <vs-slider color="primary" :text-fixed="$t('MAINSETTINGS.SETTINGS.POLLING_INTERVALL.POINT_SEC')" ticks max=59 v-model="mainsettings.polling_intervall_sec"/>
                </div>         
              </div>
            </div>
            <div class="d-flex align-items-center">
              <vs-button class="mt-3 mb-2" :disabled="!mainsettingsChanged"  type="filled" icon="save" @click="SaveSettings">{{$t('COM.SAVE')}}</vs-button>
            </div> 
          </vs-tab>
          <vs-tab :label="$t('MAINSETTINGS.TAB2.HEADER')">
            <div class="d-flex align-items-center">
              <vs-button class="mt-3 mb-2"  type="filled" icon="add" @click="AddBanner">{{$t('MAINSETTINGS.BANNERS.ADD')}}</vs-button>
            </div> 
            <div id="banner_table" class="vs-con-loading__container" >
              <vs-table
                search
                :data="banners"
                :noDataText="$t('MAINSETTINGS.BANNERS.NO_DATA')"
                stripe
                max-items="25" 
                pagination
                hoverFlat>

                <template slot="header">
                  <h3>
                    {{$t('MAINSETTINGS.BANNERS.TABLE.HEADER')}}
                  </h3>
                </template>
                <template slot="thead">              
                  <vs-th sort-key="id">
                    {{$t('MAINSETTINGS.BANNERS.TABLE.COL1')}}
                  </vs-th>
                  <vs-th sort-key="name">
                    {{$t('MAINSETTINGS.BANNERS.TABLE.COL2')}}
                  </vs-th>
                  <vs-th sort-key="description">
                    {{$t('MAINSETTINGS.BANNERS.TABLE.COL3')}}
                  </vs-th>
                  <vs-th sort-key="title">
                    {{$t('MAINSETTINGS.BANNERS.TABLE.COL4')}}
                  </vs-th>        
                  <vs-th sort-key="text">
                    {{$t('MAINSETTINGS.BANNERS.TABLE.COL5')}}
                  </vs-th>    
                  <vs-th sort-key="color">
                    {{$t('MAINSETTINGS.BANNERS.TABLE.COL6')}}
                  </vs-th>   
                  <vs-th sort-key="active">
                    {{$t('MAINSETTINGS.BANNERS.TABLE.COL7')}}
                  </vs-th> 
                  <vs-th sort-key="public">
                    {{$t('MAINSETTINGS.BANNERS.TABLE.COL8')}}
                  </vs-th> 
                  <vs-th sort-key="start">
                    {{$t('MAINSETTINGS.BANNERS.TABLE.COL9')}}
                  </vs-th> 
                  <vs-th sort-key="end">
                    {{$t('MAINSETTINGS.BANNERS.TABLE.COL10')}}
                  </vs-th> 
                  <vs-th sort-key="group_fk">
                    {{$t('MAINSETTINGS.BANNERS.TABLE.COL11')}}
                  </vs-th> 
                  <vs-th sort-key="userguid">
                    {{$t('MAINSETTINGS.BANNERS.TABLE.COL12')}}
                  </vs-th> 
                  <vs-th sort-key="sortorder">
                    {{$t('MAINSETTINGS.BANNERS.TABLE.COL13')}}
                  </vs-th>
                  <vs-th></vs-th>                                                                                                                                             
                </template>

                <template slot-scope="{data}">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data" >                      
                    <vs-td :data="tr.id">
                      {{tr.id}}
                    </vs-td>      
                    <vs-td :data="tr.name">
                      {{tr.name}}
                    </vs-td>
                    <vs-td :data="tr.descripton">
                      {{tr.descripton}}
                    </vs-td>
                    <vs-td :data="tr.title">
                      {{tr.title}}
                    </vs-td>
                    <vs-td :data="tr.text">
                      {{tr.text}}
                    </vs-td>
                    <vs-td :data="tr.color">
                      {{tr.color}}
                    </vs-td>  
                    <vs-td :data="tr.active">
                      <vs-checkbox disabled="true" v-model="tr.active"></vs-checkbox>
                    </vs-td> 
                    <vs-td :data="tr.public">
                      <vs-checkbox disabled="true" v-model="tr.public"></vs-checkbox>
                    </vs-td>  
                    <vs-td :data="tr.start">
                      {{DateToString(tr.start,false)}}
                    </vs-td> 
                    <vs-td :data="tr.end">
                      {{DateToString(tr.end,false)}}
                    </vs-td> 
                    <vs-td :data="tr.group_fk">
                      {{GetNameOfGroup(tr.group_fk)}}
                    </vs-td> 
                    <vs-td :data="tr.userguid">
                      {{GetUserNameForGuidFromUserOverview(tr.userguid)}}
                    </vs-td> 
                    <vs-td :data="tr.sortorder">
                      {{tr.sortorder}}
                    </vs-td>
                    <vs-td>
                      <vs-button  @click="EditData(tr.id)" class="ml-2" size="small" color="primary" type="filled" icon="edit"></vs-button>  
                      <vs-button  @click="DeleteBanner(tr.id)" class="ml-2" size="small" color="primary" type="filled" icon="delete"></vs-button>                        
                    </vs-td>                    
                                                                                                                                                                                              
                  </vs-tr>
                </template>
              </vs-table>
            </div>           
          </vs-tab>
        </vs-tabs>
        </vs-card>
      </vs-col>
  </vs-row>

</div>
</template>

<script>

import DatePicker from './components/DatePicker';
import helperMethods from '../helper/staticFuncHelper';
import SaveCancel from './components/SaveCancel';
import codeHelper from '../helper/staticCodeHelper';

export default {
  name: "MainSettings",
  components: {
    DatePicker,
    SaveCancel
  },
    data: function (){
      return {
      mainsettingsOld:"",
      mainsettings:{id:1,polling_intervall_min:1,polling_intervall_sec:0},// 1 min
      banners:[],
      selected:[],
      selectedOld:"",
      editMode:false,
      colors:[{value:"primary",text:this.$t('COM.COLOR.PRIMARY')},
              {value:"success",text:this.$t('COM.COLOR.SUCCESS')},
              {value:"warning",text:this.$t('COM.COLOR.WARNING')},
              {value:"danger",text:this.$t('COM.COLOR.DANGER')}],
      colorx:"primary",
      };
  },
  mounted () {
      this.LoadData();
    },

  computed: 
  { 
    mainsettingsChanged(){
      var retVal = false;
      if(this.$store.state.mainsettings.mainsettings.data != null)
      {
        retVal = this.mainsettingsOld != JSON.stringify(this.$store.state.mainsettings.mainsettings.data[0]);
      }
      return retVal;
    },
    user(){
      var retVal = [{"text": this.$t('COM.NOTHING_SELECTED'), "value":""}];
      if(this.$store.state.users.status.ready == true)
      {
          if(typeof(this.$store.state.users.useroverview) != 'undefined')
          {
              for(var i=0; i < this.$store.state.users.useroverview.length; i++)
              {
                  var name = this.GenerateTextForUserSelect(this.$store.state.users.useroverview[i]);
                  var entry = {text:name, value:this.$store.state.users.useroverview[i].userGuid} ;
                  retVal.push(entry);
              }
          }
      }
      return retVal;
    },
    allGroups(){

      var ret = [{"text": this.$t('COM.NOTHING_SELECTED'), "value":0}];
      if(this.$store.state.group.groups.data != null)
      {
        for (let i = 0; i < this.$store.state.group.groups.data.length; i++)
        {
          var txt = this.$store.state.group.groups.data[i].name;
            var val = {"text": txt, "value":this.$store.state.group.groups.data[i].id};
            ret.push(val);
        }        
      }
      return ret;
    },
    dataSelected(){
      var ret = false;
      
      if( typeof(this.selected.name) != 'undefined')
        ret = true;

      return ret;
    },
    pollingIntervallLabelText(){
      var text = "";

      if(this.mainsettings.polling_intervall_min > 0 || this.mainsettings.polling_intervall_sec > 0)
      {
        text = this.mainsettings.polling_intervall_min +" "+ this.$t('MAINSETTINGS.SETTINGS.POLLING_INTERVALL.POINT') + this.mainsettings.polling_intervall_sec +" "+ this.$t('MAINSETTINGS.SETTINGS.POLLING_INTERVALL.POINT_SEC');
      }
      else
      {
        text = this.$t('COM.OFF');
      }

      text = this.$t('MAINSETTINGS.SETTINGS.POLLING_INTERVALL.HEADER')+": "+ text;
      return text;
    },
    getTableData() {
      var data = [];
      if(this.$store.state.banner.banners.data != null)
      {
        data = this.$store.state.banner.banners.data;
      }
      return data;
    },
    loadingTableData () {
      var loading = false;
      if(typeof(this.$store.state.banner.banners.status) != 'undefined')
        loading = this.$store.state.banner.banners.loading;
      return loading;
    },
    bannerDataChanged(){
        return JSON.stringify(this.selected) != this.selectedOld;
    }

  },
  watch: {
      getTableData(value) {
          this.banners = value;
      },
      loadingTableData(value)
      {
        if(value)
        {
          this.$store.dispatch('alertqueue/showLoader', {'id':'#banner_table'});
        }
        else
        {
          this.$store.dispatch('alertqueue/hideLoader', {'id':'#banner_table'});
        }
      }
    },
  methods: {
      ...helperMethods,
      ...codeHelper,
      BannerChanged(){
         
      },


      EditNotAllowd(){
          return !this.$can('edit', 'banner');
      },
      DeleteNotAllowd(){
          return !this.$can('delete', 'banner');
      },      
      LoadData: function ()
      {
        this.LoadMainSettings();
        this.$store.dispatch('banner/getBanner', { root: true },{ root: true });
        this.$store.dispatch('group/getGroups', { root: true },{ root: true });
        this.$store.dispatch('users/getUserOverviewData', { root: true },{ root: true });

        this.$store.dispatch('totp/getTotp', { root: true },{ root: true });
      },
      LoadMainSettings(){
        this.$store.dispatch('mainsettings/getMainSettings', { root: true },{ root: true })
        .then(response => {

          if(response != null)
          {
            this.mainsettings = response[0];
            this.mainsettingsOld = JSON.stringify(response[0]);
          }
        
        });
      },

      EditData(id){
        if(this.$store.state.banner.banners.data != null)
        {
          for(var i = 0; i < this.$store.state.banner.banners.data.length; i++)
          {
            if(this.$store.state.banner.banners.data[i].id==id)
            {
              this.selectedOld = JSON.stringify(this.$store.state.banner.banners.data[i]);
              this.selected = JSON.parse(this.selectedOld);
              this.editMode = true;
              break;
            }
          }
        }
      },
      AddBanner(){
        this.selected={id:0,sortorder:0,color:"primary",group_fk:0,userguid:"",active:true,public:false};
        this.selectedOld = JSON.stringify(this.selected);
        this.editMode = true;
      },
      DeleteBanner(id){
        this.$store.dispatch('alertqueue/showLoader', {'id':'none'});
        this.$store.dispatch('banner/deleteBanner', id)
        .then(response => {
          if(response.success === true)
          {
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('MAINSETTINGS.BANNERS.DELETE.SUCCESS')});  
            this.$store.dispatch('banner/getBanner', { root: true },{ root: true });
          }
          else
          {
            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('MAINSETTINGS.BANNERS.DELETE.ERROR')});             
          }

          this.$store.dispatch('alertqueue/hideLoader', {'id':'none'});
          this.LoadData();
        }, 
        error =>{
            this.$store.dispatch('alertqueue/hideLoader', {'id':'none'});
            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('MAINSETTINGS.BANNERS.DELETE.ERROR')+": "+error});  
        });
      },
      SaveSettings(){
          this.$store.dispatch('mainsettings/updateMainSettings',  this.mainsettings)
          .then(response => {
            if(response.success === true)
            {
              this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('MAINSETTINGS.EDIT.SUCCESS')});  
              this.LoadMainSettings();
            }
            else
            {
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('MAINSETTINGS.EDIT.ERROR')});             
            }

            this.$store.dispatch('alertqueue/hideLoader', {'id':'#editBannerCard'});
            this.Cancel();
          }, 
          error =>{
              this.$store.dispatch('alertqueue/hideLoader', {'id':'#editBannerCard'});
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('MAINSETTINGS.EDIT.ERROR')+": "+error});  
              this.Cancel();
          });
      },
      Save(){
        this.$store.dispatch('alertqueue/showLoader', {'id':'#editBannerCard'});

        if(this.selected.id <= 0)
        {
          // neuer Banner
          this.$store.dispatch('banner/insertBanner', this.selected)
          .then(response => {
            if(response.success === true)
            {
              this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('MAINSETTINGS.BANNERS.INSERT.SUCCESS')});  
              this.$store.dispatch('banner/getBanner', { root: true },{ root: true });
            }
            else
            {
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('MAINSETTINGS.BANNERS.INSERT.ERROR')});             
            }

            this.$store.dispatch('alertqueue/hideLoader', {'id':'#editBannerCard'});
            this.Cancel();
            this.LoadData();
          }, 
          error =>{
              this.$store.dispatch('alertqueue/hideLoader', {'id':'#editBannerCard'});
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('MAINSETTINGS.BANNERS.INSERT.ERROR')+": "+error});  
              this.Cancel();
          });          
        }
        else
        {
          // geändert
          this.$store.dispatch('banner/updateBanner', this.selected)
          .then(response => {
            if(response.success === true)
            {
              this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('MAINSETTINGS.BANNERS.EDIT.SUCCESS')});  
              this.$store.dispatch('banner/getBanner', { root: true },{ root: true });
            }
            else
            {
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('MAINSETTINGS.BANNERS.EDIT.ERROR')});             
            }

            this.$store.dispatch('alertqueue/hideLoader', {'id':'#editBannerCard'});
            this.Cancel();
            this.LoadData();
          }, 
          error =>{
              this.$store.dispatch('alertqueue/hideLoader', {'id':'#editBannerCard'});
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('MAINSETTINGS.BANNERS.EDIT.ERROR')+": "+error});  
              this.Cancel();
          });
        }
      },
      Cancel(){
        this.$data.selected = [];
        this.editMode = false;
      }
  }
};

</script>